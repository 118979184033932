import React, { Component } from 'react';

import Header from './Header';
import Footer from './Footer';

class Index extends Component {
  componentDidMount() {
    document.querySelectorAll('.sidenav-overlay').forEach((el) => el.remove());
    document.querySelectorAll('.drag-target').forEach((el) => el.remove());
  }

  render() {
    return (
      <div>
        <Header />
        <div className='container'>
          <p className='solaris__hometext'>
            A Solaris Engenharia é uma empresa que tem como meta oferecer
            energia <span className='solaris__attention'>limpa</span>,{' '}
            <span className='solaris__attention'>renovável</span> e{' '}
            <span className='solaris__attention'>econômica</span> para seus
            clientes. A partir da conversão da energia solar em energia
            elétrica, a Solaris Engenharia apresenta{' '}
            <span className='solaris__attention'>
              soluções tecnológicas sustentáveis
            </span>{' '}
            na geração de energia solar para projetos residenciais, comerciais e
            industriais, urbanos e rurais.
          </p>
          <p className='solaris__hometext'>
            A Solaris Engenharia é especializada em{' '}
            <span className='solaris__attention'>projetos rurais</span>, visando
            otimizar o <span className='solaris__attention'>agronegócio</span>{' '}
            com a inserção de tecnologias de geração de energia. Seus{' '}
            <span className='solaris__attention'>resultados</span> comprovam
            economicidade e performance da produção.
          </p>
          <div className='center-align'>
            <img
              src='/images/home.png'
              alt='Painéis Solares em propriedade industrial'
              className='solaris__img-default'
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Index;
