import React, { Component } from 'react';

import Header from './Header';
import Footer from './Footer';

class Sobre extends Component {
  componentDidMount() {
    document.querySelectorAll('.sidenav-overlay').forEach((el) => el.remove());
    document.querySelectorAll('.drag-target').forEach((el) => el.remove());
  }

  render() {
    return (
      <div>
        <Header />
        <div className='container'>
          <div className='row'>
            <h4 className='solaris__header-about'> Sobre </h4>
          </div>
          <div className='row'>
            <p className='solaris__about-text center-align'>
              A Solaris Engenharia é uma empresa que oferece projetos, vendas de
              kits geradores de energia fotovoltaica, produtos de eficiência
              energética e sistemas de backup residencial e industrial.
              Realizamos seu projeto, venda e instalação.
            </p>
          </div>
          <div className='row'>
            <h4 className='solaris__header-about'> Equipe </h4>
          </div>
          <div className='row'>
            <div className='col l4 m4 s12'>
              <div id='profile-page-wall-post' className='card small'>
                <div className='card-profile-title'>
                  <div className='row'>
                    <div className='col s12 center-align'>
                      <p className='solaris__card-name'>Tulio M. Gonçalves</p>
                      <span className='grey-text text-darken-1 ultra-small'>
                        Fundador & CEO
                      </span>
                    </div>
                    <div className='col s1 right-align'>
                      <i className='mdi-navigation-expand-more'></i>
                    </div>
                  </div>
                </div>
                <div className='card-content'>
                  <p>
                    Engenheiro eletricista, pela Universidade Católica de Goiás,
                    especializado em energia solar Fotovoltaica. Projetista e
                    fundador da Solaris Engenharia.
                  </p>
                </div>
              </div>
            </div>
            <div className='col l4 m4 s12'>
              <div id='profile-page-wall-post' className='card small'>
                <div className='card-profile-title'>
                  <div className='row'>
                    <div className='col s12 center-align'>
                      <p className='solaris__card-name'>José G. Viana</p>
                      <span className='grey-text text-darken-1 ultra-small'>
                        Consultor de Vendas
                      </span>
                    </div>
                    <div className='col s1 right-align'>
                      <i className='mdi-navigation-expand-more'></i>
                    </div>
                  </div>
                </div>
                <div className='card-content'>
                  <p>
                    Administrador com larga experiência no setor comercial,
                    especializado em projetos voltados para energia solar
                    fotovoltaica em agronegócios.
                  </p>
                </div>
              </div>
            </div>
            <div className='col l4 m4 s12'>
              <div id='profile-page-wall-post' className='card small'>
                <div className='card-profile-title'>
                  <div className='row'>
                    <div className='col s12 center-align'>
                      <p className='solaris__card-name'>Cleiber Nichida</p>
                      <span className='grey-text text-darken-1 ultra-small'>
                        Eng. Eletricista
                      </span>
                    </div>
                    <div className='col s1 right-align'>
                      <i className='mdi-navigation-expand-more'></i>
                    </div>
                  </div>
                </div>
                <div className='card-content'>
                  <p>
                    Engenheiro eletricista, pelo Instituto Federal de Goiás,
                    especializado em Projetos.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <h4 className='solaris__header-info'>Informações Empresariais</h4>
          </div>
          <div className='row'>
            <p>
              <b>Razão Social:</b> Solaris Serviços de Engenharia Eireli - ME
            </p>
            <p>
              <b>CNPJ:</b> 27.612.021/0001-14
            </p>
            <p>
              <b>Endereço:</b> Rua Pinheiro Chagas n. 226 Qd. 208A Lt.7, Setor
              Sudoeste, Goiânia-GO. CEP 74.303-036
            </p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Sobre;
