import React, { Component } from 'react';

import Modal from './Modal';

import M from 'materialize-css/dist/js/materialize.min.js';

class SolarisCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false, // Exibe Modal
      showContactModal: false, // Modal de Fale Conosco
      modalType: 'solaris', // Decide qual modal exibir, deve ser sempre 'solaris' ou 'financiamento'
      worthItButtons: false, // Verifica se vale a pena ou não
      kwpGenerator: 0, // Gerador de %value% kWp - calculado pelo sistema
      generatorCost: '', // Custo do Gerador - calculado pelo sistema
      averageConsumption: 0, // Input do Usuário - consumo médio (KW/mês)
      monthlyCost: '', // Input do Usuário - gasto mensal com energia (R$)
      financeValue: '', // Financiamento - Valor a financiar
      intentionParcels: 60, // Financiamento - Quantidade de Parcelas
      parcelValuePerMonth: 0, // Valor das Parcelas de Financiamento
    };

    // Solaris Calculator
    this.handleAvgChange = this.handleAvgChange.bind(this);
    this.handleCostChange = this.handleCostChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // Finance Calculator
    this.handleFinanceChange = this.handleFinanceChange.bind(this);
    this.handleParcelChange = this.handleParcelChange.bind(this);
    this.handleFinanceSubmit = this.handleFinanceSubmit.bind(this);
    // Other Methods
    this.handleFinanceInterest = this.handleFinanceInterest.bind(this);
    // Blur Methods
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount() {
    let elems = document.querySelectorAll('.collapsible');
    M.Collapsible.init(elems, {});
  }

  componentDidUpdate() {
    let elems = document.querySelectorAll('.collapsible');
    M.Collapsible.init(elems, {});
  }

  handleContactUsModal = (event) => {
    this.setState({
      showContactModal: !this.state.showContactModal,
    });
  };

  handleShowModal = (event) => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  handleAvgChange = (event) => {
    this.setState({ averageConsumption: event.target.value });
  };

  handleCostChange = (event) => {
    this.setState({ monthlyCost: event.target.value });
  };

  handleBlur = (e) => {
    let num = parseFloat(this.state.financeValue);
    let cleanNum = num.toFixed(2);
    this.setState({ financeValue: cleanNum });
  };

  handleFinanceChange = (event) => {
    this.setState({ financeValue: event.target.value });
  };

  handleParcelChange = (event) => {
    this.setState({ intentionParcels: event.target.value });
  };

  handleFinanceInterest = (event) => {
    this.setState({
      showModal: false,
      financeValue: this.state.generatorCost.toFixed(2),
    });
    let collToClose = document.querySelector('#solaris-calculator');
    let collToOpen = document.querySelector('#finance-calculator');
    let executeClose = M.Collapsible.getInstance(collToClose);
    let executeOpen = M.Collapsible.getInstance(collToOpen);
    executeClose.close();
    executeOpen.open();
  };

  handleFinanceSubmit = (event) => {
    event.preventDefault();
    this.setState({ modalType: 'financiamento' });
    // ************************************* //
    // Modo de Cálculo
    //
    // CF = ________i________        // "i" dividido por linha abaixo
    //      1 - (1 + i) ^ -n
    //
    // CF: Coeficiente de Financiamento
    // i: juros por mês
    // n: Período
    //
    // Para determinar o valor da parcela, basta multiplicar o valor do financiamento pelo coeficiente de financiamento
    // Parcels = CF * financeValue
    // ************************************* //

    let interest = 0.01; // Juros de 1% ao mês
    let cf = 0; // Coeficiente de Financiamento
    let parcelValue = 0; // Valor da Parcela

    let exponent = -1 * this.state.intentionParcels; // Expoente do divisor

    let divisor = 1 - Math.pow(1 + interest, exponent);

    cf = interest / divisor;

    parcelValue = cf * this.state.financeValue;

    this.setState({ parcelValuePerMonth: parcelValue });
    this.handleShowModal(event);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ modalType: 'solaris' });
    let baseValues = {};
    let calculationResult = 0; // investimento inicial com a energia solar

    let response = await fetch('https://api.npoint.io/74418fc0dc1db87cf1dc');
    if (response.ok) {
      baseValues = await response.json();
    } else {
      alert('HTTP-Error: ' + response.status);
    }

    const baseCalc = (this.state.averageConsumption * 12) / 1500; // Gerador de x kWp

    if (baseCalc > 0 && baseCalc <= 5) {
      calculationResult = baseCalc * baseValues.first_case;
    } else if (baseCalc > 5 && baseCalc <= 15) {
      calculationResult = baseCalc * baseValues.second_case;
    } else if (baseCalc > 15) {
      calculationResult = baseCalc * baseValues.third_case;
    }

    if (calculationResult < this.state.monthlyCost * 120) {
      this.setState({
        worthItButtons: true,
        kwpGenerator: baseCalc,
        generatorCost: calculationResult,
      });
    } else {
      this.setState({
        worthItButtons: false,
      });
    }
    this.handleShowModal(event);
  };

  render() {
    let buttons;
    let modalText;
    if (this.state.modalType === 'solaris') {
      if (this.state.worthItButtons) {
        modalText = (
          <div className='modal-solaris__content'>
            <div id='solaris__velocimeter'>
              <span className='solaris__velocimeter-bg'></span>
              <span className='solaris__velocimeter-needle'></span>
            </div>
            <div className='modal-solaris__content__text'>
              <p>COM CERTEZA, VOCE SÓ TEM A GANHAR</p>
              <p>
                {this.state.kwpGenerator} kWp -{' '}
                {this.state.generatorCost.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                *
              </p>
            </div>
          </div>
        );
        buttons = (
          <div className='modal-solaris__actions'>
            <button
              className='waves-effect waves-light btn'
              onClick={this.handleContactUsModal}
            >
              FAZER CONTATO
            </button>
            <button
              className='waves-effect waves-light btn'
              onClick={this.handleFinanceInterest}
            >
              SIMULAR FINANCIAMENTO
            </button>
            <p className='modal-solaris__footnote'>
              *Valor médio, sujeito a variação
            </p>
          </div>
        );
      } else {
        modalText = (
          <div className='modal-solaris__content'>
            <div id='solaris__velocimeter'>
              <span className='solaris__velocimeter-bg'></span>
              <span className='solaris__velocimeter-needle-reject'></span>
            </div>
            <div className='modal-solaris__content__text'>
              <p>APARENTEMENTE AINDA NÃO VALE A PENA</p>
            </div>
          </div>
        );
        buttons = (
          <div className='modal-solaris__actions'>
            <button
              className='waves-effect waves-light btn'
              onClick={this.handleContactUsModal}
            >
              QUERO TER CERTEZA
            </button>
          </div>
        );
      }
    } else if (this.state.modalType === 'financiamento') {
      modalText = (
        <div className='modal-solaris__content'>
          <div className='modal-solaris__content__text'>
            <p>
              PARCELAS MENSAIS DE{' '}
              {this.state.parcelValuePerMonth.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </p>
          </div>
        </div>
      );
      buttons = (
        <div className='modal-solaris__actions'>
          <button
            className='waves-effect waves-light btn'
            onClick={this.handleContactUsModal}
          >
            FAZER CONTATO
          </button>
          <p className='modal-solaris__footnote'>
            *Valor aproximado, sujeito a variação
          </p>
        </div>
      );
    }
    return (
      <div className='row calculators'>
        <div className='col s12 m4 l4 offset-l4 offset-m4'>
          <ul
            className='collapsible solaris-customized'
            id='solaris-calculator'
          >
            <li className='solaris-curved'>
              <div className='collapsible-header'>
                <span className='center-text'>Saiba se vale a pena</span>
              </div>
              <div className='collapsible-body'>
                <form onSubmit={this.handleSubmit}>
                  <div className='row'>
                    <div className='input-field col s12'>
                      <label className='active' htmlFor='averageConsumption'>
                        Consumo Médio (KW/mês)
                      </label>
                      <input
                        id='averageConsumption'
                        name='averageConsumption'
                        type='number'
                        onChange={this.handleAvgChange}
                        placeholder={this.state.averageConsumption}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='input-field col s12'>
                      <label className='active' htmlFor='monthlyCost'>
                        Gasto Mensal com Energia (R$)
                      </label>
                      <input
                        id='monthlyCost'
                        name='monthlyCost'
                        type='number'
                        step='.02'
                        onChange={this.handleCostChange}
                        placeholder={'0.00'}
                      />
                    </div>
                  </div>
                  <button className='btn solaris__button'>Calcular</button>
                </form>
              </div>
            </li>
          </ul>
        </div>
        <div className='col s12 m4 l4'>
          <ul
            className='collapsible solaris-customized'
            id='finance-calculator'
          >
            <li className='solaris-curved'>
              <div className='collapsible-header'>
                <span className='center-text'>Simule um Financiamento</span>
              </div>
              <div className='collapsible-body'>
                <form onSubmit={this.handleFinanceSubmit}>
                  <div className='row'>
                    <div className='input-field col s12'>
                      <label className='active' htmlFor='financeValue'>
                        Valor (R$)
                      </label>
                      <input
                        id='financeValue'
                        name='financeValue'
                        type='number'
                        step='.01'
                        value={this.state.financeValue}
                        onChange={this.handleFinanceChange}
                        onBlur={this.handleBlur}
                        placeholder={'0.00'}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='input-field col s12'>
                      <label className='active' htmlFor='intentionParcels'>
                        Parcelas
                      </label>
                      <input
                        disabled
                        id='intentionParcels'
                        name='intentionParcels'
                        type='number'
                        value={this.state.intentionParcels}
                        onChange={this.handleParcelChange}
                      />
                    </div>
                  </div>
                  <button className='btn solaris__button'>Calcular</button>
                </form>
              </div>
            </li>
          </ul>
        </div>
        <Modal
          onClose={this.handleShowModal}
          show={this.state.showModal}
          timeToWait={3000}
        >
          {modalText}
          {buttons}
        </Modal>
        <Modal
          onClose={this.handleContactUsModal}
          show={this.state.showContactModal}
          timeToWait={500}
        >
          <div className='modal-solaris__content-contact center-align'>
            <p className='solaris__attention-modal'>CONTATO</p>
            <p className='solaris__hometext'>contato@solariseng.com.br</p>
            <p className='solaris__hometext'>(62) 3434-0034 - WhatsApp</p>
            <p className='solaris__hometext'>(62) 99980-0034</p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default SolarisCalculator;
