import React, { Component } from 'react';

import './styles.css';

class Modal extends Component {
  onClose = (e) => {
    this.props.onClose && this.props.onClose(e);
  };

  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <div className='overlay'>
        <div className='modal-solaris' id='modal-solaris'>
          <div className='modal-solaris__header'>
            <img
              src='/images/solaris_logo.png'
              className='modal-solaris__logo'
              alt='Solaris Engenharia'
            />
            <a className='modal-solaris__close' onClick={this.onClose}>
              &times;
            </a>
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Modal;
