import React from 'react';
import { Link } from 'react-router-dom';

import SolarisCalculator from './SolarisCalculator';

import './styles.css';

const Footer = () => {
  return (
    <div className='solaris__bottom'>
      <SolarisCalculator />
      <footer className='footer row'>
        <div className='col s12 m4 l4 footer__item'>
          <div className='footer__content'>
            <p className='footer__h2'>SOLARIS ENGENHARIA</p>
            <p className='footer__text'>
              Rua Pinheiro Chagas, 226 Quadra 208A Lote 07
            </p>
            <p className='footer__text'>
              Setor Sudoeste | Goiânia-GO - CEP 74.303-036
            </p>
          </div>
        </div>
        <div className='col s12 m4 l4 footer__item'>
          <div className='footer__content-link'>
            <Link to='/sobre' className='footer__h2'>
              QUEM SOMOS
            </Link>
          </div>
        </div>
        <div className='col s12 m4 l4 footer__item'>
          <div className='footer__content'>
            <p className='footer__h2'>CONTATO</p>
            <p className='footer__text'>contato@solariseng.com.br</p>
            <p className='footer__text'>(62) 3434-0034 - WhatsApp</p>
            <p className='footer__text'>(62) 99980-0034</p>
          </div>
        </div>
        <div className='footer__end'></div>
      </footer>
    </div>
  );
};

export default Footer;
