import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Index from './Index';
import TecnologiaSolar from './TecnologiaSolar';
import Servicos from './Servicos';
import Viabilidade from './Viabilidade';
import Sobre from './Sobre';
import SaibaMais from './SaibaMais';

// import 'materialize-css/dist/css/materialize.min.css';

const App = () => {
  return (
    <BrowserRouter>
      <Route path='/' exact component={Index} />
      <Route path='/tecnologia-solar' exact component={TecnologiaSolar} />
      <Route path='/servicos' exact component={Servicos} />
      <Route path='/Viabilidade' exact component={Viabilidade} />
      <Route path='/sobre' exact component={Sobre} />
      <Route path='/saiba-mais' exact component={SaibaMais} />
    </BrowserRouter>
  );
};

export default App;
