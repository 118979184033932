import React, { Component } from 'react';

import Header from './Header';
import Footer from './Footer';

class Servicos extends Component {
  componentDidMount() {
    document.querySelectorAll('.sidenav-overlay').forEach((el) => el.remove());
    document.querySelectorAll('.drag-target').forEach((el) => el.remove());
  }

  render() {
    return (
      <div>
        <Header />
        <div className='container'>
          <h5 className='solaris__text-header'>Também oferecemos:</h5>
          <div className='solaris__services-block center-align'>
            <img
              src='/images/servicos/servicos_01.jpeg'
              alt='Imagem de um banco de capacitores'
              className='solaris__img-vertical'
            />
            <p className='solaris__text-centered'>
              _ Projeto e instalação de banco de capacitores para correção de
              fator de potência;
            </p>
          </div>
          <div className='solaris__services-block center-align'>
            <img
              src='/images/servicos/servicos_02.jpeg'
              alt='Imagem de um banco de capacitores'
              className='solaris__img-default'
            />
            <p className='solaris__text-centered'>
              _ Ajuste de demanda contratada;
            </p>
          </div>
          <div className='solaris__services-block center-align'>
            <img
              src='/images/servicos/servicos_03.jpeg'
              alt='Imagem de um quadro elétrico'
              className='solaris__img-vertical'
            />
            <p className='solaris__text-centered'>
              _ Projeto e manutenção de quadros elétricos;
            </p>
          </div>
          <div className='solaris__services-block center-align'>
            <img
              src='/images/servicos/servicos_04.jpeg'
              alt='Imagem de uma subestação de painéis solares'
              className='solaris__img-default'
            />
            <p className='solaris__text-centered'>
              _ Projeto e manutenção de subestações;
            </p>
          </div>
          <div className='solaris__services-block center-align'>
            <img
              src='/images/servicos/servicos_05.jpeg'
              alt='Imagem de uma estação de painéis solares'
              className='solaris__img-default'
            />
            <p className='solaris__text-centered'>_ Extensão de rede;</p>
          </div>
          <div className='solaris__services-block center-align'>
            <img
              src='/images/servicos/servicos_06.jpeg'
              alt='Imagem de uma audição de sistemas elétricos'
              className='solaris__img-vertical'
            />
            <p className='solaris__text-centered'>
              _ Laudo de sistemas elétricos;
            </p>
          </div>
          <div className='solaris__services-block center-align'>
            <img
              src='/images/servicos/servicos_07.jpeg'
              alt='Imagem de um sistema de combate a incêndio'
              className='solaris__img-default'
            />
            <p className='solaris__text-centered'>
              _ Sistemas de combate a incêndio;
            </p>
          </div>
          <div className='solaris__services-block center-align'>
            <img
              src='/images/servicos/servicos_08.jpeg'
              alt='Imagem de uma estação de painéis solares'
              className='solaris__img-default'
            />
            <p className='solaris__text-centered'>
              _ Consultoria para compra de energia no mercado livre;
            </p>
          </div>
          <div className='solaris__services-block center-align'>
            <img
              src='/images/servicos/servicos_09.jpeg'
              alt='Imagem de painéis solares em uma residência'
              className='solaris__img-default'
            />
            <p className='solaris__text-centered'>_ Automação residencial.</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Servicos;
