import React, { Component } from 'react';

import Header from './Header';
import Footer from './Footer';

class SaibaMais extends Component {
  componentDidMount() {
    document.querySelectorAll('.sidenav-overlay').forEach((el) => el.remove());
    document.querySelectorAll('.drag-target').forEach((el) => el.remove());
  }

  render() {
    return (
      <div>
        <Header />
        <div className='container'>
          <h5 className='solaris__text-header-bold'>
            _ Linkteca Sobre Energia Solar
          </h5>
          <p className='solaris__text'>
            <a
              href='http://www.absolar.org.br/'
              target='_blank'
              rel='noopener noreferrer'
            >
              _ Associação Brasileira de Energia Solar Fotovoltáica
            </a>
          </p>
          <p className='solaris__text'>
            <a
              href='https://www.youtube.com/watch?v=AFnXQRP0FHk'
              target='_blank'
              rel='noopener noreferrer'
            >
              _ Programa Biosfera - Episódio: ENERGIA SOLAR
            </a>
          </p>
          <p className='solaris__text'>
            <a
              href='http://www2.aneel.gov.br/cedoc/ren2012482.pdf'
              target='_blank'
              rel='noopener noreferrer'
            >
              _ Regulamentação ANEEL - RESOLUÇÃO NORMATIVA Nº 482, DE 17 DE
              ABRIL DE 2012
            </a>
          </p>
          <p className='solaris__text'>
            <a
              href='http://www2.aneel.gov.br/cedoc/ren2015687.pdf'
              target='_blank'
              rel='noopener noreferrer'
            >
              _ Regulamentação ANEEL - RESOLUÇÃO NORMATIVA Nº 687, DE 24 DE
              NOVEMBRO DE 2015
            </a>
          </p>
          <p className='solaris__text'>
            <a
              href='http://www.ons.org.br/paginas/energia-agora/carga-e-geracao'
              target='_blank'
              rel='noopener noreferrer'
            >
              _ Geração e consumo do Brasil, em tempo real
            </a>
          </p>
          <h5 className='solaris__text-header-bold'>
            _ Alguns Clientes - Cases
          </h5>
          <div className='solaris__services-block center-align'>
            <img
              src='/images/cases/cases_01.png'
              alt='Imagem dos painéis solares do O Boticário'
              className='solaris__img-default'
            />
            <p className='solaris__text-centered'>_ O Boticário;</p>
          </div>
          <div className='solaris__services-block center-align'>
            <img
              src='/images/cases/cases_02.png'
              alt='Imagem dos painéis solares do Cartório 1º Ofício'
              className='solaris__img-default'
            />
            <p className='solaris__text-centered'>_ Cartório 1º Ofício;</p>
          </div>
          <div className='solaris__services-block center-align'>
            <img
              src='/images/cases/cases_03.png'
              alt='Imagem dos painéis solares do Gustavo Cruvinel - Armazém Secador'
              className='solaris__img-default'
            />
            <p className='solaris__text-centered'>
              _ Gustavo Cruvinel - Armazém Secador;
            </p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default SaibaMais;
