import React, { Component } from 'react';

import Header from './Header';
import Footer from './Footer';

class TecnologiaSolar extends Component {
  componentDidMount() {
    document.querySelectorAll('.sidenav-overlay').forEach((el) => el.remove());
    document.querySelectorAll('.drag-target').forEach((el) => el.remove());
  }

  render() {
    return (
      <div>
        <Header />
        <div className='container'>
          <h5 className='solaris__text-header-bold'>O que é?</h5>
          <p className='solaris__text'>
            A tecnologia solar é a{' '}
            <span className='solaris__attention'>
              conversão da energia vinda do Sol em energia elétrica
            </span>{' '}
            para uso geral. É uma energia limpa, renovável e sustentável, em
            abundância no Brasil.
          </p>
          <h5 className='solaris__text-header-bold'>Como funciona</h5>
          <p className='solaris__text'>
            <span className='solaris__attention'>Placas fotovoltaicas</span>{' '}
            fazem a captação da irradiação solar e a convertem em energia
            elétrica. O{' '}
            <span className='solaris__attention'>inversor de frequência</span>{' '}
            converte essa energia para o padrão de nossa rede elétrica,
            permitindo seu{' '}
            <span className='solaris__attention'>
              uso imediato e irrestrito
            </span>
            .
          </p>
          <h5 className='solaris__text-header-bold'>Vantagens</h5>
          <p className='solaris__text'>
            <b>_ Economia</b> - os equipamentos têm média de vida útil de 30
            anos. O investimento inicial é recuperado, em média, de 3 a 4 anos,
            resultando em{' '}
            <span className='solaris__attention'>
              economia por mais de 25 anos
            </span>
            .
          </p>
          <p className='solaris__text'>
            <b>_ Rentabilidade</b> - o excedente de energia gerada pelo sistema
            solar pode ser{' '}
            <span className='solaris__attention'>
              devolvido para a rede elétrica
            </span>{' '}
            (concessionária), e ser{' '}
            <span className='solaris__attention'>deduzido</span> em qualquer
            conta de energia do mesmo titular.
          </p>
          <p className='solaris__text'>
            <b>_ Autonomia</b> - gerando a própria energia, o usuário{' '}
            <span className='solaris__attention'>reduz</span> drasticamente sua{' '}
            <span className='solaris__attention'>vulnerabilidade</span> a
            variação dos valores de{' '}
            <span className='solaris__attention'>tarifa</span> aplicados pela
            concessionária de energia elétrica.
          </p>
          <p className='solaris__text'>
            <b>_ Responsabilidade Ambiental</b> - ao gerar a sua própria
            energia, <span className='solaris__attention'>evitamos</span> a
            utilização de usinas{' '}
            <span className='solaris__attention'>termelétricas</span>, que
            produzem energia não renovável, com a queima de{' '}
            <span className='solaris__attention'>combustível fóssil</span>.
          </p>
          <p className='solaris__text'>
            <b>_ Responsabilidade Social</b> - ao utilizar a geração própria, o
            usuário <span className='solaris__attention'>libera a energia</span>{' '}
            não utilizada da rede elétrica,{' '}
            <span className='solaris__attention'>permitindo</span> seu uso em
            áreas prioritárias, vinculadas ao{' '}
            <span className='solaris__attention'>
              desenvolvimento econômico
            </span>
            .
          </p>
          <div className='center-align'>
            <img
              src='/images/tecnologia-solar.png'
              alt='Painéis Solares em propriedade privada'
              className='solaris__img-default'
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default TecnologiaSolar;
