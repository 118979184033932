import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

import M from 'materialize-css/dist/js/materialize.min.js';

class Header extends Component {
  componentDidMount() {
    let sidenav = document.querySelector('#mobile-navbar');
    M.Sidenav.init(sidenav, { preventScrolling: false });
  }

  render() {
    return (
      <div>
        <nav id='topo' className='row'>
          <div className='nav-wrapper nav-bar'>
            <a href='/' className='nav-logo'>
              <img
                src='/images/solaris_logo.png'
                className='logo'
                alt='Solaris Engenharia'
              />
            </a>
            <a href='#' data-target='mobile-navbar' className='sidenav-trigger'>
              <i className='material-icons'>menu</i>
            </a>
            <ul id='nav-mobile' className='nav-list right hide-on-med-and-down'>
              <li>
                <Link to='/tecnologia-solar' id='bg-item'>
                  tecnologia solar
                </Link>
              </li>
              <li>
                <Link to='/servicos' id='bg-item'>
                  serviços
                </Link>
              </li>
              <li>
                <Link to='/viabilidade' id='bg-item'>
                  viabilidade
                </Link>
              </li>
              <li>
                <Link to='/sobre' id='bg-item'>
                  sobre
                </Link>
              </li>
              <li>
                <Link to='/saiba-mais' id='bg-item'>
                  saiba +
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        <ul className='sidenav' id='mobile-navbar'>
          <li>
            <Link to='/tecnologia-solar'>tecnologia solar</Link>
          </li>
          <li>
            <Link to='/servicos'>serviços</Link>
          </li>
          <li>
            <Link to='/viabilidade'>viabilidade</Link>
          </li>
          <li>
            <Link to='/sobre'>sobre</Link>
          </li>
          <li>
            <Link to='/saiba-mais'>saiba +</Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default Header;
