import React, { Component } from 'react';

import Header from './Header';
import Footer from './Footer';

class Estrategia extends Component {
  componentDidMount() {
    document.querySelectorAll('.sidenav-overlay').forEach((el) => el.remove());
    document.querySelectorAll('.drag-target').forEach((el) => el.remove());
  }

  render() {
    return (
      <div>
        <Header />
        <div className='container'>
          <h5 className='solaris__text-header-bold'>_ Análise Financeira</h5>
          <p className='solaris__text'>
            Verificação de consumo médio histórico, avaliando a necessidade e
            economicidade da implantação do sistema.
          </p>
          <h5 className='solaris__text-header-bold'>
            _ Análise de Condições Técnicas
          </h5>
          <p className='solaris__text'>
            Verificação do local de instalação, como área disponível, posição e
            estrutura existente.
          </p>

          <div className='center-align'>
            <img
              src='/images/viabilidade.png'
              alt='Painéis Solares em propriedade industrial'
              className='solaris__img-default'
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Estrategia;
